import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VMenu,
    {
      attrs: {
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "full-width": "",
        "min-width": "290px"
      },
      on: { input: _vm.onCalendarClose },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                _vm._g({ staticClass: "datepicker" }, on),
                [
                  _c(VTextField, {
                    staticClass: "border-label-input",
                    attrs: {
                      value: _vm.reportDateValue,
                      label: _vm.reportDateLabel,
                      readonly: "",
                      outline: "",
                      "append-icon": _vm.analyticsDashboard
                        ? "mdi-menu-down"
                        : "mdi-calendar-range",
                      "hide-details": ""
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(VDatePicker, {
        attrs: {
          value: _vm.dpDates,
          "allowed-dates": _vm.allowedDates,
          maxRange: _vm.maxRange,
          multiple: ""
        },
        on: { input: _vm.changedDate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
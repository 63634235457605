<!-- A custom component to do ranges using Vuetify since v-datepicker doesn't support ranges functionality -->
<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    @input="onCalendarClose"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <div class="datepicker" v-on="on">
        <v-text-field
          :value="reportDateValue"
          :label="reportDateLabel"
          class="border-label-input"
          readonly
          outline
          :append-icon="analyticsDashboard ? 'mdi-menu-down' : 'mdi-calendar-range'"
          hide-details
        ></v-text-field>
      </div>
    </template>
    <v-date-picker
      :value="dpDates"
      @input="changedDate"
      :allowed-dates="allowedDates"
      :maxRange="maxRange"
      multiple
    ></v-date-picker>
  </v-menu>
</template>

<script>
import DateTime from 'luxon/src/datetime.js';

export default {
  props: {
    dates: {
      type: Object,
      default: () => ({
        start: '',
        end: '',
      }),
    },
    reportDateValue: String,
    reportDateLabel: String,
    allowedDates: Function,
    analyticsDashboard: {
      type: Boolean,
      default: false,
      required: false,
    },
    maxRange: {
      type: Number,
      default: () => -1,
    },
  },
  data: () => ({
    dpDates: [],
    menu: false,
  }),
  watch: {
    dates: {
      handler() {
        this.dpDates = this.generateDatesInRange([this.dates.start, this.dates.end]);
      },
      immediate: true,
    },
  },
  methods: {
    onCalendarClose(isOpen = false) {
      if (isOpen === true) return; // we react only on close
      if (!this.dpDates.length) return;
      this.$emit('update:dates', {
        start: this.dpDates[0],
        end: this.dpDates[this.dpDates.length - 1],
      });
    },
    generateDatesInRange(_dates = []) {
      const start = DateTime.fromISO(_dates[0]);
      let end = DateTime.fromISO(_dates[_dates.length - 1]);
      const rangeInDays = end.diff(start, 'days').toObject().days;

      if (this.maxRange > 0 && rangeInDays >= this.maxRange) {
        end = start.plus({ days: this.maxRange - 1 });
        this.$toast.warning(`Date range is limited to ${this.maxRange} days.`);
      }
      const rangeDates = [];
      if (start.isValid && end.isValid) {
        let dpDate = start;
        do {
          rangeDates.push(dpDate.toISODate());
          dpDate = dpDate.plus({ days: 1 });
        } while (dpDate <= end);
      }
      return rangeDates;
    },
    changedDate(val = []) {
      let new_dates = val.sort();
      const prevDpDates = [...this.dpDates];
      if (new_dates.length === 0 && prevDpDates.length === 1) {
        new_dates = prevDpDates;
      }
      if (new_dates.length < prevDpDates.length) {
        new_dates = prevDpDates.filter((prevDate) => !new_dates.includes(prevDate));
      }
      if (new_dates.length > prevDpDates.length && prevDpDates.length > 1) {
        new_dates = new_dates.filter((prevDate) => !prevDpDates.includes(prevDate));
      }
      this.dpDates = this.generateDatesInRange(new_dates);
    },
  },
  mounted() {
    this.dpDates = this.generateDatesInRange([this.dates.start, this.dates.end]);
  },
};
</script>

<style scoped>
.disabled {
  background-color: #ffffff;
}
</style>

<style>
input[type='time'] {
  cursor: text;
}
</style>
